import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import VideoPreview from "components/ui/base/VideoPreview";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import ProductHunt from "components/ui/base/ProductHunt";
import TestimonialWithImageMichele from "components/ui/extended/TestimonialWithImageMichele";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import FunnelCarousel from "components/ui/extended/FunnelCarousel";
import MarketingAutomationAltFeatures from "components/ui/extended/MarketingAutomationAltFeatures";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";

interface MarketingFunnelPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: MarketingFunnelPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.marketing-funnel.seo.title")}
        description={t("pages.features.marketing-funnel.seo.description")}
        image={{
          relativePath: "meta/best-sales-funnel-software.jpg",
          alt: "Sales Funnel Software to automate your sales",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.marketing-funnel.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.marketing-funnel.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.features.marketing-funnel.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.features.marketing-funnel.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.features.marketing-funnel.title")}
        description={t("pages.features.marketing-funnel.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={900}
        descriptionMaxWidth={500}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Marketing Funnel",
            path: "/features/marketing-funnel/",
          },
        ]}
        video={{
          path: "pages/solutions/marketing-automation/funnel-creation.mov",
        }}
      />

      <ProductHunt
        url={
          "https://www.producthunt.com/posts/visual-funnels-maker-from-customerly?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-visual-funnels-maker-from-customerly"
        }
      />

      <VideoPreview
        id={"funnel-builder"}
        title={t("pages.features.marketing-funnel.1.title")}
        paragraph={t("pages.features.marketing-funnel.1.subtitle")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath:
              "pages/features/marketing-funnel/marketing-automation-funnel-template-builder.mp4",
          },
        ]}
      />

      <MarketingAutomationAltFeatures location={location} />

      <FunnelCarousel
        title={t("pages.features.marketing-funnel.2.title")}
        description={t("pages.features.marketing-funnel.2.subtitle")}
      />

      <ImageWithDescription
        title={t("pages.features.marketing-funnel.3.title")}
        description={t("pages.features.marketing-funnel.3.subtitle")}
        image={{
          relativePath:
            "pages/features/marketing-funnel/business-intelligence-crm-audience-segmentation.png",
          alt: t("pages.features.marketing-funnel.3.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/features/audience-segmentation/",
          text: t("pages.features.marketing-funnel.3.cta"),
        }}
      />

      <TestimonialWithImageMichele />

      <RegisterForCustomerService />

      <Customers />

      <QuestionsAndAnswers
        title={t("pages.features.marketing-funnel.faq.title")}
        description={t("pages.features.marketing-funnel.faq.description")}
        elements={[1, 2, 3].map((index) => {
          return {
            question: t(
              `pages.features.marketing-funnel.faq.${index}.question`
            ),
            answer: t(`pages.features.marketing-funnel.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const MarketingFunnelQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/best-sales-funnel-software.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
